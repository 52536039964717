
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SwiperCore, { Navigation, Pagination, Scrollbar, EffectCoverflow } from 'swiper/core'
import 'swiper/swiper.min.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, EffectCoverflow])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        swiperOptions: {
            require: true,
            type: Object,
            default: Object,
        },
        sliderData: {
            require: true,
            type: Array || Object,
            default: Array,
        },
    },
}
