import sanitizeHtml from '@/mixins/sanitizeHtml'

export default {
    mixins: [sanitizeHtml],
    methods: {
        decorateTranslateText (text, indexDecorate = 1) {
            const arrayIndexDecorate = typeof indexDecorate === 'number' ? [indexDecorate] : indexDecorate
            const arrayWords = text.split(' ')
            for (let i = 0; i < arrayIndexDecorate.length; i++) {
                if (arrayIndexDecorate[i] === 'last') {
                    arrayWords[arrayWords.length - 1] =
                `<span class="accent-gradient-color-text">${arrayWords[arrayWords.length - 1]}</span>`
                } else if (arrayWords[arrayIndexDecorate[i]]) {
                    arrayWords[arrayIndexDecorate[i]] =
                    `<span class="accent-gradient-color-text">${arrayWords[arrayIndexDecorate[i]]}</span>`
                }
            }
            return this.sanitizeTextHtml(arrayWords.join(' '))
        },
    },
}
