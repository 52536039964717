
import decorateTranslateText from '@/mixins/decorateTranslateText'

export default {
    mixins: [decorateTranslateText],
    data () {
        return {
            services: [
                {
                    video: 'https://cdn.thrivestate.app/website/1.m4v',
                    title: this.$t('HOME_PAGE.SERVICES.CARD_1.TITLE'),
                    description: this.$t('HOME_PAGE.SERVICES.CARD_1.DESCRIPTION'),
                },
                {
                    video: 'https://cdn.thrivestate.app/website/2.m4v',
                    title: this.$t('HOME_PAGE.SERVICES.CARD_2.TITLE'),
                    description: this.$t('HOME_PAGE.SERVICES.CARD_2.DESCRIPTION'),
                },
                {
                    video: 'https://cdn.thrivestate.app/website/3.m4v',
                    title: this.$t('HOME_PAGE.SERVICES.CARD_3.TITLE'),
                    description: this.$t('HOME_PAGE.SERVICES.CARD_3.DESCRIPTION'),
                },
            ],
        }
    },
}
