
export default {
    props: {
        service: {
            type: Object,
            required: true,
            default: Object,
        },
    },
    methods: {
        requireSrc (img) {
            return require(`~/assets/images/home/services/${img}`)
        },
    },
}
