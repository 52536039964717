
import decorateTranslateText from '@/mixins/decorateTranslateText'

export default {
    mixins: [decorateTranslateText],
    data () {
        return {
            partnerAdvantages: [
                {
                    id: 0,
                    image: 'PartnerAdvantage1.svg',
                    title: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_1.TITLE'),
                    description: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_1.DESCRIPTION'),
                },
                {
                    id: 1,
                    image: 'PartnerAdvantage2.svg',
                    title: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_2.TITLE'),
                    description: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_2.DESCRIPTION'),
                },
                {
                    id: 2,
                    image: 'PartnerAdvantage3.svg',
                    title: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_3.TITLE'),
                    description: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_3.DESCRIPTION'),
                },
                {
                    id: 3,
                    image: 'PartnerAdvantage4.svg',
                    title: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_4.TITLE'),
                    description: this.$t('HOME_PAGE.PARTNER_ADVANTAGES.CARD_4.DESCRIPTION'),
                },
            ],
        }
    },
    methods: {
        requireSrc (img) {
            return require(`~/assets/images/home/partnerAdvantages/${img}`)
        },
    },
}
