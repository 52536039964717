import { render, staticRenderFns } from "./SliderDefault.vue?vue&type=template&id=89b67dea"
import script from "./SliderDefault.vue?vue&type=script&lang=js"
export * from "./SliderDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SlidersSliderControlButton: require('/usr/src/app/components/sliders/SliderControlButton.vue').default})
