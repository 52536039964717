
import { required, email } from 'vuelidate/lib/validators'
import { apiCreateLead } from '@/services/lead'
import sendWhatsAppMessage from '@/mixins/sendWhatAppMessage'
import { apiGetAgent } from '@/services/agents'
import handleToken from '@/mixins/handleToken'

export default {
    mixins: [handleToken, sendWhatsAppMessage],
    validations () {
        return {
            lead: {
                name: { required },
                email: { required, email },
                phone: { required },
            },
        }
    },
    data () {
        return {
            translations: {
                phoneNumberLabel: this.$t('HOME_PAGE.CONSULTATION.FORMS_ELEMENTS.ELEMENT_3'),
            },
            lead: {
                name: null,
                email: null,
                phone: null,
            },
            agent: null,
        }
    },
    computed: {
        loading () {
            return this.$store.state.loading
        },
        getAgentNumber () {
            return this.agent.phone_country.phone_code + this.agent.phone
        },
    },
    watch: {
        async '$route.query.utm_source' () {
            await this.createLead()
        },
    },
    methods: {
        async createLead () {
            this.$v.$touch()
            if (!this.$v.lead.$error) {
                if (!this.$route.query.utm_source) {
                    if (this.getToken()) {
                        await this.$router.push({ query: {
                            ...this.$route.query, ...this.getToken() },
                        })
                    } else {
                        await this.$router.push({ query: {
                            ...this.$route.query, utm_source: 'organic' },
                        })
                    }
                } else {
                    this.$store.commit('startLoading')
                    const response = await apiCreateLead(this.lead)
                    this.$gtm.push({ event: 'signUpCompleted' })
                    const data = {
                        ...this.lead,
                        ...this.getToken(),
                        fullUrl: window.location.href.split('?')[0],
                    }

                    if (response) {
                        this.$v.lead.name.$model = null
                        this.$v.lead.email.$model = null
                        this.$v.lead.phone.$model = null
                        this.$nextTick(() => { this.$v.$reset() })
                    }
                    this.$store.commit('endLoading')
                    try {
                        await this.$axios({
                            method: 'post',
                            url: 'postback',
                            baseURL: '/',
                            data,
                        })
                    } catch (err) {}
                }
            }
        },
        async createWhatsAppMessage () {
            this.agent = await apiGetAgent()
            this.sendWhatsAppMessage(this.agent.whatsapp || this.getAgentNumber)
        },
    },
}
