
import decorateTranslateText from '@/mixins/decorateTranslateText'

export default {
    mixins: [decorateTranslateText],
    data () {
        return {
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-location-button-next',
                    prevEl: '.swiper-location-button-prev',
                },
                loop: true,
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                breakpoints: {
                    1440: {
                        spaceBetween: 32,
                    },
                    768: {
                        spaceBetween: 25,
                    },
                    500: {
                        spaceBetween: 25,
                        loop: false,
                        centeredSlides: false,
                    },
                    0: {
                        spaceBetween: 20,
                        loop: false,
                        centeredSlides: false,
                    },
                },
            },
            sliderData: [
                {
                    image: 'Palm Jumeirah.jpg',
                    logoTitle: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_3.TITLE'),
                    description: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_3.DESCRIPTION'),
                },
                {
                    image: 'Downtown.jpg',
                    logoTitle: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_4.TITLE'),
                    description: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_4.DESCRIPTION'),
                },
                {
                    image: 'Dubai Hills.jpg',
                    logoTitle: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_5.TITLE'),
                    description: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_5.DESCRIPTION'),
                },
                {
                    image: 'Dubai Marina.jpg',
                    logoTitle: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_1.TITLE'),
                    description: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_1.DESCRIPTION'),
                },
                {
                    image: 'Business Bay.jpg',
                    logoTitle: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_2.TITLE'),
                    description: this.$t('HOME_PAGE.LOCATION.LOCATIONS.CARD_2.DESCRIPTION'),
                },
            ],
        }
    },
    methods: {
        requireSrc (img) {
            return require(`~/assets/images/home/services/${img}`)
        },
    },
}
