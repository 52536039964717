
import sliderArrowLeft from '@/assets/icons/arrows/sliderArrowLeft.svg?raw'
import sliderArrowRight from '@/assets/icons/arrows/sliderArrowRight.svg?raw'
import sanitizeSvgHtml from '@/mixins/sanitizeHtml.js'

export default {
    mixins: [sanitizeSvgHtml],
    props: {
        navigation: {
            type: Object || undefined,
            default: null,
        },
        pagination: {
            type: Object || undefined,
            default: null,
        },
        scrollbar: {
            type: Object || undefined,
            default: null,
        },
    },
    data () {
        return {
            sliderArrowLeft,
            sliderArrowRight,
        }
    },
    methods: {
        clearClass (classValue) {
            return classValue.slice(1, classValue.length)
        },
    },
}
